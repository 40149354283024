import type { TargetGroupResult } from '@pharmasuisse/types/commerce'
import { DrupalRole } from '~/stores/drupalUser'

/**
 * Get the computed target group for the given required roles.
 */
export function useCommerceTargetGroup(
  roles: string[] = [],
): TargetGroupResult {
  const { $texts } = useNuxtApp()

  const individualMember = roles.includes(DrupalRole.IndividualMember)
  const nonMember = roles.includes(DrupalRole.NonMember)
  const affiliatedPharmacy = roles.includes(DrupalRole.AffiliatedPharmacy)
  const nonAffiliatedPharmacy = roles.includes(DrupalRole.NonAffiliatedPharmacy)
  const totalRoles = roles.length

  // The initial state of the values assumes that anyone can buy the product.
  let color = ''
  let label = ''
  let icon = ''
  let name = ''

  // Handle product flags.
  switch (totalRoles) {
    case 1:
      if (individualMember || nonMember) {
        color = 'orange'
        label = $texts('shop.forPharmacists', 'Produkt für Apotheker/in')
        icon = 'individual_member'
        name = 'pharmacists'
      } else if (affiliatedPharmacy || nonAffiliatedPharmacy) {
        color = 'berry'
        label = $texts('shop.forPharmacies', 'Produkt für Apotheken')
        icon = 'pharmasuisse'
        name = 'pharmacies'
      }
      break
    case 2:
      if (affiliatedPharmacy && nonAffiliatedPharmacy) {
        color = 'berry'
        label = $texts('shop.forPharmacies', 'Produkt für Apotheken')
        icon = 'pharmasuisse'
        name = 'pharmacies'
      } else if (individualMember && nonMember) {
        color = 'orange'
        label = $texts('shop.forPharmacists', 'Produkt für Apotheker/in')
        icon = 'individual_member'
        name = 'pharmacists'
      }
      break
    default:
      color = ''
      label = ''
      icon = ''
      name = ''
  }

  return {
    color,
    label,
    icon,
    name,
    buttonProps: {
      [color]: true,
    },
  }
}
