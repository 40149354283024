<template>
  <div v-if="prices">
    <!-- @TODO: Add again once we have the information about the quantity and unit. -->
    <!-- <div class="uppercase font-medium text-xs">1 Stück</div> -->

    <div>
      <div
        v-if="priceLarge"
        :class="{
          'text-md md:text-xl lg:text-2xl uppercase !leading-none lg:inline-block':
            !isFreeLargePrice,
          'text-md md:text-lg lg:text-lg !leading-none lg:inline-block':
            isFreeLargePrice,
        }"
      >
        {{ priceLarge }}
      </div>

      <div
        v-if="priceSmall"
        class="text-sm lg:ml-10 leading-none lg:inline-block"
      >
        {{ priceSmall }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  FieldItemTypeCommercePriceFragment,
  FieldTypeRoleBasedPriceFragment,
} from '#graphql-operations'
import { useDrupalUser } from '~/stores/drupalUser'
import { useGlobalConfig } from '~/stores/globalConfig'

const drupalUser = useDrupalUser()
const globalConfig = useGlobalConfig()
const { $texts } = useNuxtApp()

const props = defineProps<{
  // The general price without any discounts.
  formatted?: string

  // The price for the current user.
  calculated?: FieldItemTypeCommercePriceFragment['calculated']

  // The roles required to buy the product.
  roles?: string[]

  roleBasedPrices?: FieldTypeRoleBasedPriceFragment[]
}>()

type Prices = {
  large: string
  small?: string
}

const requiredRole = computed(() => props.roles?.[0] || '')

const prices = computed<Prices | undefined>(() => {
  if (!props.calculated?.formatted) {
    return
  }

  if (requiredRole) {
    const userRole = drupalUser.primaryRole || 'anonymous'
    const key = userRole + '__' + requiredRole.value
    if (globalConfig.priceLogic[key]) {
      return globalConfig.priceLogic[key]
    }
  }

  return {
    large: props.calculated?.formatted,
  }
})

const discountedPrice = computed(() => {
  return (
    props.roleBasedPrices?.find((user) => user.role === requiredRole.value)
      ?.formatted || ''
  )
})

function isFreeProduct(price: string) {
  const formattedPrice = price.replace(/\s+/g, '')
  return /^CHF?0\.?00$|^0\.?00CHF?$/.test(formattedPrice)
}

const isFreeLargePrice = computed(() =>
  isFreeProduct(renderPrice(prices.value?.large)),
)

const priceLarge = computed(() =>
  isFreeLargePrice.value
    ? $texts('free', 'Kostenlos')
    : renderPrice(prices.value?.large),
)

const priceSmall = computed(() => {
  const price = renderPrice(prices.value?.small)

  if (price && !price.includes(discountedPrice.value)) {
    return price
  }
  if (isFreeProduct(price)) {
    return $texts('free', 'Kostenlos')
  }
})

function renderPrice(v = ''): string {
  return v
    .replace('@price', props.formatted || '')
    .replace('@discounted', discountedPrice.value || '')
}
</script>
