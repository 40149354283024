<template>
  <span
    class="text-gray-900 h-20 lg:h-30 px-10 font-medium text-2xs lg:text-xs uppercase rounded items-center inline-flex"
    :class="color || 'bg-gray-100'"
    ><slot>{{ text }}</slot></span
  >
</template>

<script lang="ts">
export default defineComponent({
  name: 'Tag',
  props: {
    text: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes() {
      return []
    },
  },
})
</script>

<style lang="postcss"></style>
